import getParam from '../utils/getParam';

export * from './units';

export const PROMOTIONS_DEFAULT_PARAMS = {
    brandId: getParam('brandId'),
    campaignId: getParam('campaignId'),
    p: getParam('p'), // Promotion ID's 1,2,3,4...
    width: Number(getParam('width', 300)),
    height: Number(getParam('height', 250)),
    link: getParam('link', 'marketplace'),
    extensionType: getParam('extensionType', null),
    extensionId: getParam('extensionId', ''),
    updateCache: getParam('updateCache', false),
    viewSettings: {},
    exclude: false,
    excludeStudy: false,
    promotions: [],
    pixels: []
};

export const widgetSizeCountProductsInUnit = {
    '300x250': 6,
    '300x600': 6,
    '320x100': 2,
    '336x280': 6,
    '970x250': 8,
    '728x90': 4,
    '160x600': 6,
    '320x50': 1
};

export const analyticsEventMap = {
    clicked: 'click',
    loaded: 'pageView',
    touchmove: 'touchMove',
    touchstart: 'touchStart',
    touchend: 'touchEnd',
    mouseover: 'mouseover',
    'click-through': 'click-through'
};

export const liveRampEventMap = {
    clicked: 'click',
    loaded: 'pageView'
};
