import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Units from './components/units';
import { useAppContext } from './hooks/index';
import getParam from './shared/utils/getParam';
import App from './App';

const WidgetWrapper = () => {
    const appContext = useAppContext();

    useEffect(() => {
        const cm = getParam('cm', '');
        const adId = getParam('adId', uuidv4());

        if (window.klpixel) {
            window.klpixel('config', 'adId', adId);
        }

        appContext.init({
            version: getParam('version', 'v1'),
            adId,
            eventSource: getParam('eventSource', 'widget'),
            layout: getParam('layout', ''),
            cm: cm ? decodeURIComponent(cm) : cm,
            layoutType: getParam('layoutType', '')
        });
    }, []);

    return (
        <App>
            <Units />
        </App>
    );
};

export default WidgetWrapper;
